import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Snackbar, Step, StepLabel, Stepper, Typography } from '@mui/material';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import AppBarMenu from '../../ui/AppBarMenu';

import { RegistrarInformacionScreen } from './RegistrarInformacionScreen';
import { RegistrarPartesScreen } from './RegistrarPartesScreen';
import { RegistrarPrestacionesScreen } from './RegistrarPrestacionesScreen';
import { RegistrarAnexosScreen } from './RegistrarAnexosScreen';
import { GenerarAcuseScreen } from './GenerarAcuseScreen';

import DigitalizarDocumentosScreen from './DigitalizarDocumentosScreen';

import { ActualizarEstatus, DetalleRecepcion } from '../../../actions/recepcion/recepcionActions';

import moment from 'moment';

import { types } from '../../../types/types';
import { InformacionSeguimientoSimple } from '../../ui/InformacionSeguimiento';


export const DemandaInicialScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);

    const [ajaxLoading, setAjaxLoading] = useState(false);
    const [ajaxFinish, setAjaxFinish] = useState(false);    

    const [noSeguimiento, setNoSeguimiento] = useState( '' );

    const [alerta, setAlerta] = useState({ type: 'error', msg: '' });

    const [open, setOpen] = useState( false );

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [provieneBuzon, setProvieneBuzon] = useState( false );
    const [escritoBuzon, setEscritoBuzon] = useState( '' );
    const [acuseEnvioBuzon, setAcuseEnvioBuzon] = useState( '' );
    const [anexosBuzon, setAnexosBuzon] = useState( [] );

    const [openAnexos, setOpenAnexos] = useState( false );  
    const [openArchivoAnexo, setOpenArchivoAnexo] = useState( false );  
    const [archivoAnexo, setArchivoAnexo] = useState( '' );
    
    const [loadingFinish, setLoadingFinish] = useState( false );  

    const { type, msg } = alerta;

    const handleNext = () => {
        setActiveStep( (prevStep) => prevStep + 1);
    }

    const handleBack  = async () => {                        
        setActiveStep( (prevStep) => prevStep - 1);
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);        
    };

    const Finalizar = async () => {

        const params = {
            idRecepcion: Id,
            idEstatus: 47
        }

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200 ){                    
                    
                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                    setAjaxFinish( true );

                    dispatch({ type: types.eliminarRecepcion }); 

                }
                else{

                    const { message } = response.data;

                    setAlerta({ type: 'error', msg: message });

                    setOpen( true );
                    setLoadingFinish( false );
                }

            }

        });           

    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                idRecepcion: Id
            }

            dispatch({ type: types.openLoading });

            await DetalleRecepcion( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){           
                            
                            if( response.data.idEstatus === 1 && response.data.usuarioActualAsignacion ){ setActiveStep( 0 ); }
                            else if( response.data.idEstatus === 50 && response.data.usuarioActualAsignacion){ setActiveStep( 1 ); }
                            else if( response.data.idEstatus === 51 && response.data.usuarioActualAsignacion){ setActiveStep( 2 ); }
                            else if( response.data.idEstatus === 52 && response.data.usuarioActualAsignacion){ setActiveStep( 3 ); }
                            else if( response.data.idEstatus === 2 && response.data.usuarioActualAsignacion){ setActiveStep( 4 ); }
                            else if( response.data.idEstatus === 3 && response.data.usuarioActualAsignacion){ setActiveStep( 5 ); }
                            else if( response.data.idEstatus === 47 ){  
                                
                                setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                                setAjaxFinish( true );                                 
                            }   
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setAjaxFinish( true ); 
                                }     
                            }  

                            if( response.data.idEstatus !== 47 ){

                                setNoSeguimiento( response.data.id );

                                setProvieneBuzon( response.data.provieneBuzonElectronico );
                                setEscritoBuzon( response.data.urlEscritoBuzonElectronico );   
                                setAcuseEnvioBuzon( response.data.urlAcuseEnvioBuzonElectronico );   
                                setAnexosBuzon( response.data.anexosBuzonElectronico ?? [] );                      

                                dispatch({

                                    type: types.actualizarRecepcion,                                
                                    payload: {        

                                        id: response.data.id,
                                        fechaRecepcion: response.data.fechaRecepcion,    
                                        presentante: response.data.presentante,      
                                        acuse: response.data.acuse,                         
                                        acuseFirmado: response.data.acuseFirmado,
                                        folioBuzonElectronico: response.data.folioBuzonElectronico,
                                        provieneBuzonElectronico: response.data.provieneBuzonElectronico,
                                    }
                                }); 
                                
                                dispatch({

                                    type: types.actualizarDemandaInicial,                              
                                    payload: {
            
                                        idTipoJuicio: response.data.idTipoJuicio,
                                        tipoJuicio: response.data.tipoJuicio,
                                        recibidoBuzon: response.data.recibidoBuzonElectronico,
                                        recibidoReloj: response.data.recibidoPorReloj,
                                        asignacionDirecta: response.data.asignacionDirecta,
                                        motivoAsignacion: response.data.motivoAsignacion,
                                        idPonencia: response.data.idPonencia,
                                        ponencia: response.data.ponencia,
                                        fechaReloj: response.data.recibidoPorReloj ? moment( new Date( response.data.fechaReloj ) ).format("YYYY-MM-DDTHH:mm") : '',
                                        numeroExpediente : response.data.numeroExpediente
                                    }
                                }); 
                                
                                dispatch({
                                    type: types.agregarPartes,
                                    payload: response.data.partes,  
                                }); 

                                dispatch({
                                    type: types.agregarPrestaciones,
                                    payload: response.data.prestaciones,  
                                }); 

                                dispatch({
                                    type: types.agregarAnexos,
                                    payload: response.data.anexos,  
                                }); 
                                
                            }

                            setTimeout(() => {
                                
                                setAjaxLoading( true );

                                dispatch({ type: types.closeLoading });

                            }, 1000);

                        }

                    }

                }

            });

        }     
        
        if( Id ){

            Obtener();

        }

    }, [ Id, dispatch ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            <AppBarMenu />    

            <Snackbar open={ open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 3000 } onClose={ handleClose }>
                <Alert onClose={ handleClose } variant="filled" severity={ type }sx={{ width: '100%' }}>
                    { msg }
                </Alert>
            </Snackbar>     

            {
                ajaxFinish
                ?
                    <Box sx={{ marginTop: '10em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        { mensajeFinalizacion }
                                    </Typography>

                                    {
                                        usuarioAsignacion
                                        ?
                                            <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                { usuarioAsignacion }
                                            </Typography>
                                        :
                                        null
                                    }

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>
                :

                    <>

                        <Box sx={{ marginTop: '7em', marginBottom: '2em' }} >

                            <Container maxWidth="lg">                    
                                
                                <Stepper activeStep={activeStep} alternativeLabel >
                                    <Step>
                                        <StepLabel>Registrar información</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>Registrar parte(s)</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>Registrar prestacione(s)</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>Registrar anexo(s)</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>Generar acuse de recibo</StepLabel>
                                    </Step>    
                                
                                    {
                                        !provieneBuzon
                                        &&
                                        <Step>
                                            <StepLabel>Escanear documento(s)</StepLabel>
                                        </Step>
                                    }

                                </Stepper>
                                
                                {
                                    activeStep === 0 ? (
                                        <>                            
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 
                                                            
                                                <Grid item md={12} xs={12}>

                                                    <Grid container spacing={3}>

                                                        <Grid item md={6} xs={6}>

                                                            <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />

                                                        </Grid>

                                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}>

                                                            <Grid container spacing={3}>

                                                                <Grid item 
                                                                    md={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 } 
                                                                    xs={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 }
                                                                    sx={{ textAlign: 'right' }}
                                                                >

                                                                    {
                                                                        provieneBuzon
                                                                        &&
                                                                            <Grid container spacing={3}>

                                                                                <Grid item sm={ acuseEnvioBuzon ? 6 : 12 } sx={{ textAlign: acuseEnvioBuzon ? 'center' : 'right' }}>

                                                                                    <Button
                                                                                        target='_blank'
                                                                                        href={ escritoBuzon }
                                                                                        variant='contained'
                                                                                    >
                                                                                        VER ESCRITO
                                                                                    </Button>

                                                                                </Grid>

                                                                                {
                                                                                    acuseEnvioBuzon
                                                                                    &&
                                                                                        <Grid item sm={6} sx={{ textAlign: 'center' }}>

                                                                                            <Button
                                                                                                onClick={ () => { setArchivoAnexo( acuseEnvioBuzon ); setOpenArchivoAnexo( true ); } }
                                                                                                variant='contained'
                                                                                            >
                                                                                                VER ACUSE DE ENVÍO
                                                                                            </Button>

                                                                                        </Grid>
                                                                                }

                                                                            </Grid>                                                                    
                                                                    
                                                                    }
                                                                </Grid>

                                                                {
                                                                    ( provieneBuzon && anexosBuzon?.length > 0 )
                                                                    &&
                                                                    <Grid item md={4} xs={4} sx={{ textAlign: 'right' }}>
                                                                        
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={ () => setOpenAnexos(true) }
                                                                        >
                                                                            VER ANEXOS 
                                                                        </Button>                                                                    

                                                                    </Grid>
                                                                }

                                                            </Grid>

                                                        </Grid>                                                   

                                                    </Grid>

                                                </Grid>
                                                            
                                                <RegistrarInformacionScreen handleNext={ handleNext } idRecepcion={ Id } />  

                                            </Grid>
                                        </>
                                    ) :

                                    activeStep === 1 ? (
                                        <>
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                                <Grid item md={12} xs={12}>

                                                    <Grid container spacing={3}>

                                                        <Grid item md={6} xs={6}>

                                                            <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />

                                                        </Grid>

                                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}>

                                                            <Grid container spacing={3}>

                                                                <Grid item 
                                                                    md={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 } 
                                                                    xs={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 }
                                                                    sx={{ textAlign: 'right' }}
                                                                >

                                                                    {
                                                                        provieneBuzon
                                                                        &&
                                                                            <Grid container spacing={3}>

                                                                                <Grid item sm={ acuseEnvioBuzon ? 6 : 12 } sx={{ textAlign: acuseEnvioBuzon ? 'center' : 'right' }}>

                                                                                    <Button
                                                                                        target='_blank'
                                                                                        href={ escritoBuzon }
                                                                                        variant='contained'
                                                                                    >
                                                                                        VER ESCRITO
                                                                                    </Button>

                                                                                </Grid>

                                                                                {
                                                                                    acuseEnvioBuzon
                                                                                    &&
                                                                                        <Grid item sm={6} sx={{ textAlign: 'center' }}>

                                                                                            <Button
                                                                                                onClick={ () => { setArchivoAnexo( acuseEnvioBuzon ); setOpenArchivoAnexo( true ); } }
                                                                                                variant='contained'
                                                                                            >
                                                                                                VER ACUSE DE ENVÍO
                                                                                            </Button>

                                                                                        </Grid>
                                                                                }

                                                                            </Grid>                                                                    
                                                                        
                                                                    }

                                                                </Grid>

                                                                {
                                                                    ( provieneBuzon && anexosBuzon?.length > 0 )
                                                                    &&
                                                                    <Grid item md={4} xs={4} sx={{ textAlign: 'right' }}>
                                                                        
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={ () => setOpenAnexos(true) }
                                                                        >
                                                                            VER ANEXOS 
                                                                        </Button>                                                                    

                                                                    </Grid>
                                                                }

                                                            </Grid>

                                                        </Grid>     

                                                    </Grid>

                                                </Grid>

                                                <RegistrarPartesScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } />

                                            </Grid>
                                        </>
                                    ) :

                                    activeStep === 2 ? (
                                        <>
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                                <Grid item md={12} xs={12}>

                                                    <Grid container spacing={3}>

                                                        <Grid item md={6} xs={6}>

                                                            <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />

                                                        </Grid>

                                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}>

                                                            <Grid container spacing={3}>

                                                                <Grid item 
                                                                    md={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 } 
                                                                    xs={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 }
                                                                    sx={{ textAlign: 'right' }}
                                                                >

                                                                    {
                                                                        provieneBuzon
                                                                        &&
                                                                            <Grid container spacing={3}>

                                                                                <Grid item sm={ acuseEnvioBuzon ? 6 : 12 } sx={{ textAlign: acuseEnvioBuzon ? 'center' : 'right' }}>

                                                                                    <Button
                                                                                        target='_blank'
                                                                                        href={ escritoBuzon }
                                                                                        variant='contained'
                                                                                    >
                                                                                        VER ESCRITO
                                                                                    </Button>

                                                                                </Grid>

                                                                                {
                                                                                    acuseEnvioBuzon
                                                                                    &&
                                                                                        <Grid item sm={6} sx={{ textAlign: 'center' }}>

                                                                                            <Button
                                                                                                onClick={ () => { setArchivoAnexo( acuseEnvioBuzon ); setOpenArchivoAnexo( true ); } }
                                                                                                variant='contained'
                                                                                            >
                                                                                                VER ACUSE DE ENVÍO
                                                                                            </Button>

                                                                                        </Grid>
                                                                                }

                                                                            </Grid>                                                                    
                                                                        
                                                                    }

                                                                </Grid>

                                                                {
                                                                    ( provieneBuzon && anexosBuzon?.length > 0 )
                                                                    &&
                                                                    <Grid item md={4} xs={4} sx={{ textAlign: 'right' }}>
                                                                        
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={ () => setOpenAnexos(true) }
                                                                        >
                                                                            VER ANEXOS 
                                                                        </Button>                                                                    

                                                                    </Grid>
                                                                }

                                                            </Grid>

                                                        </Grid>     

                                                    </Grid>

                                                </Grid>

                                                <RegistrarPrestacionesScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } />                                      

                                            </Grid>
                                        </>
                                    ) :

                                    activeStep === 3 ? (
                                        <>
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                                <Grid item md={12} xs={12}>

                                                    <Grid container spacing={3}>

                                                        <Grid item md={6} xs={6}>

                                                            <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />

                                                        </Grid>

                                                        <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}>

                                                            <Grid container spacing={3}>

                                                                <Grid item 
                                                                    md={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 } 
                                                                    xs={ ( provieneBuzon && anexosBuzon?.length > 0 ) ? 8 : 12 }
                                                                    sx={{ textAlign: 'right' }}
                                                                >

                                                                    {
                                                                        provieneBuzon
                                                                        &&
                                                                            <Grid container spacing={3}>

                                                                                <Grid item sm={ acuseEnvioBuzon ? 6 : 12 } sx={{ textAlign: acuseEnvioBuzon ? 'center' : 'right' }}>

                                                                                    <Button
                                                                                        target='_blank'
                                                                                        href={ escritoBuzon }
                                                                                        variant='contained'
                                                                                    >
                                                                                        VER ESCRITO
                                                                                    </Button>

                                                                                </Grid>

                                                                                {
                                                                                    acuseEnvioBuzon
                                                                                    &&
                                                                                        <Grid item sm={6} sx={{ textAlign: 'center' }}>

                                                                                            <Button
                                                                                                onClick={ () => { setArchivoAnexo( acuseEnvioBuzon ); setOpenArchivoAnexo( true ); } }
                                                                                                variant='contained'
                                                                                            >
                                                                                                VER ACUSE DE ENVÍO
                                                                                            </Button>

                                                                                        </Grid>
                                                                                }

                                                                            </Grid>                                                                    
                                                                        
                                                                    }

                                                                </Grid>

                                                                {
                                                                    ( provieneBuzon && anexosBuzon?.length > 0 )
                                                                    &&
                                                                    <Grid item md={4} xs={4} sx={{ textAlign: 'right' }}>
                                                                        
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={ () => setOpenAnexos(true) }
                                                                        >
                                                                            VER ANEXOS 
                                                                        </Button>                                                                    

                                                                    </Grid>
                                                                }

                                                            </Grid>

                                                        </Grid>     

                                                    </Grid>

                                                </Grid>

                                                <RegistrarAnexosScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } setAjaxFinish={ setAjaxFinish } setUsuarioAsignacion={ setUsuarioAsignacion } />

                                            </Grid>
                                        </> 
                                    ) : 

                                    activeStep === 4 ? (
                                                
                                        <>                                       
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '2em' }} component={ Paper } > 
                                            
                                                <GenerarAcuseScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } setAjaxFinish={ setAjaxFinish } setUsuarioAsignacion={ setUsuarioAsignacion } Finalizar={ Finalizar } />

                                            </Grid>
                                            
                                        </>                              
                                                                
                                    ) :

                                    activeStep === 5 ? (
                                        <>                                
                                            <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper }> 

                                                <Grid  item md={12} xs={12}>
                                                    <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />
                                                </Grid>

                                                <DigitalizarDocumentosScreen 
                                                    handleBack={ handleBack } 
                                                    Finalizar={ Finalizar } 
                                                    idRecepcion={ Id } 
                                                    setAjaxFinish={ setAjaxFinish } 
                                                    setUsuarioAsignacion={ setUsuarioAsignacion } 
                                                    loadingFinish={ loadingFinish }
                                                    setLoadingFinish={ setLoadingFinish }
                                                />

                                            </Grid>
                                        </> 
                                    ) 
                                    : 
                                    null                     
                                }

                            </Container>

                        </Box>       

                        <Dialog 
                            open={ openAnexos } 
                            onClose={ () => {} } 
                            fullWidth
                            maxWidth="sm" 
                        >

                            <DialogTitle>
                                Anexos
                            </DialogTitle>
                            
                            <DialogContent>
                                
                                <List>

                                {
                                    anexosBuzon
                                    .map( ( elem, index ) => (
                                        
                                        <ListItemButton key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                            <ListItemIcon>
                                                <FileCopyIcon color='primary' />
                                            </ListItemIcon>
                                            <ListItemText primary={ elem.archivo } />
                                        </ListItemButton>

                                    ))
                                }

                                </List>
                                
                            </DialogContent>

                            <DialogActions>

                                <Button color="primary" onClick={ () => { setOpenAnexos( false ); } }>
                                    Cerrar
                                </Button>

                            </DialogActions>                

                        </Dialog>  

                        <Dialog 
                            open={ openArchivoAnexo } 
                            onClose={ () => {} } 
                            fullWidth
                            maxWidth="md" 
                        >

                            <DialogTitle>
                                Anexo
                            </DialogTitle>
                        
                        <DialogContent>
                            
                            <Grid container spacing={3} >                                                
                            
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                        
                                        <iframe title='acuse' src={ archivoAnexo } height="500px" width="100%"></iframe>

                                    </Grid>
                                
                            </Grid>
                            
                        </DialogContent>

                        <DialogActions>

                            <Button color="primary" onClick={ () => { setOpenArchivoAnexo( false ); setArchivoAnexo( '' ); } }>
                                Cerrar
                            </Button>

                        </DialogActions>                

                        </Dialog> 

                    </>               
            }
        </>
    )
}
