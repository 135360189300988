import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import useStyles from '../../../theme/useStyles';

export const ListItemBooks = () => {

    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);
  
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };
    
    return (
        <>
            <ListItemText className={classes.listItemOptions} inset>
                <Button color="inherit" className={classes.buttonIcon} onClick={ handleOpen }>
                    <LibraryBooksIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography}>
                        Libros Electrónicos
                    </Typography>
                    
                    <KeyboardArrowDownIcon />                            
                </Button>
            </ListItemText>

            <Menu elevation={ 2 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>                

                <Link to="/Libro/LibroRegistro" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de registro (Libro de gobierno)</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroRegistroValores" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de registro de valores</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroAuxiliar" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de promociones irregulares y <br /> expedientillos auxiliares</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroAmparo" className={classes.link}>                
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de amparo</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroExhortoLibrado" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de exhortos librados</ListItemText>
                    </MenuItem>              
                </Link>
                
                <Link to="/Libro/LibroExhortoRecibido" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro exhortos recibidos</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroActuario" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de actuarios</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroExpedientesConcluidos" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de expedientes concluidos</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroSentenciaDefinitiva" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de sentencia definitiva</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroSentenciaInterlocutoria" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de sentencia interlocutoria</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroPromocionesRecibidas" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de promociones recibidas</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroExpedientesfacilitadosConsulta" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de expedientes facilitados para consulta</ListItemText>
                    </MenuItem>
                </Link>

                <Link to="/Libro/LibroIndiceAlfabetico" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de índice alfabético</ListItemText>
                    </MenuItem>        
                </Link>   

                
                <Link to="/Libro/LibroMedioImpugnacion" className={classes.link}>
                    <MenuItem className={ classes.menuItem } onClick={ handleClose } > 
                        <ListItemIcon className={ classes.listItemIcon }> <LibraryBooksIcon /> </ListItemIcon>
                        <ListItemText>Libro de medio de impugnación</ListItemText>
                    </MenuItem>        
                </Link>   

            </Menu>  
        </>
    )
}
