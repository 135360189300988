import React, { useEffect, useState } from 'react';
import Dynamsoft from 'dwt';

import { Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ScannerIcon from '@mui/icons-material/Scanner';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { LoadingButton } from '@mui/lab';

import { ActualizarEstatus } from '../../../actions/recepcion/recepcionActions';

const DigitalizarDocumentosScreen = ( { handleBack, Finalizar, idRecepcion, setAjaxFinish, setUsuarioAsignacion } ) => {

    const containerId = 'dwtcontrolContainer';
    const width = "100%";
    const height = "500";
   
    const [DWObject, setDWObject] = useState( null );
    const [scanners, setScanners] = useState( [] );
    const [currentScanner, setCurrentScanner] = useState( "0" );
    const [twainReady, setTwainReady] = useState( false );    
    
    const [loadingBack, setLoadingBack] = useState( false ); 
    const [loadingFinish, setLoadingFinish] = useState( false );  
    const [countPages, setCountPages] = useState( 0 ); 

    const [error, setError] = useState( { type: 'warning', message: '' } );  

    const ReadyTwain = () => {

        Dynamsoft.DWT.ProductKey = process.env.REACT_APP_API_KEY_DYNAMIC_TWAIN;
        Dynamsoft.DWT.ResourcesPath = "../../dwt-resources";
        Dynamsoft.DWT.Containers = [{ ContainerId: containerId, Width: width, Height: height }];

        Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {

            setDWObject( Dynamsoft.DWT.GetWebTwain( containerId ) );

        });

        checkScriptLoaded();
    }

    const checkScriptLoaded = () => {        
        if ( Dynamsoft.Lib.detect.viewerScriptLoaded && !twainReady.scriptLoaded ) {            
            Dynamsoft.DWT.Load();
            setTwainReady( true );
        } 
        else {
            setTimeout(() => {
                checkScriptLoaded();               
            }, 1000);
        }
    }

    const onSourceChange = ( value ) => {
        setCurrentScanner( value );
    }

    const acquireImage = () => {

        DWObject.CloseSource();
        
        for (let i = 0; i < DWObject.SourceCount; i++) {
            if ( DWObject.GetSourceNameItems(i) === currentScanner) {
                DWObject.SelectSourceByIndex(i);
                break;
            }
        }

        DWObject.IfDuplexEnabled = false;
        DWObject.IfFeederEnabled = true;
        DWObject.IfShowUI = true;
        DWObject.PixelType = Dynamsoft.DWT.EnumDWT_PixelType.TWPT_BW;
        DWObject.Resolution = 300;

        DWObject.OpenSource();
        
        DWObject.AcquireImage( () => { setCountPages( DWObject.HowManyImagesInBuffer ); });     
    }

    const GuardarDigitalizacion = () => {
        
        setError( '' );

        if ( DWObject ) {
            if ( DWObject.HowManyImagesInBuffer > 0 ){

                const strHTTPServer = window.location.hostname;				
                const strActionPage = "Api/Irregular/GuardarDigitalizacion/" + idRecepcion ?? 0;
                
                if( window.location.protocol === 'http:' ){
                    DWObject.IfSSL = false;
                    DWObject.HTTPPort = 5000;
                }
                else if( window.location.protocol === 'https:' ){
                    DWObject.IfSSL = true;
                }      

                const data = JSON.parse(window.localStorage.getItem('data'));

                if( data ){
                    const { token } = data;
                    DWObject.SetHTTPHeader( 'Authorization', 'Bearer ' + token);
                    DWObject.SetHTTPHeader( 'Access-Control-Allow-Origin', '*');
                }               
                
                DWObject.HTTPUploadAllThroughPostAsPDF(strHTTPServer, strActionPage, "documento.pdf", OnHttpUploadSuccess, OnHttpUploadFailure);	
            }
            else{

                setError( { type: 'warning', message: 'Debe escanear o subir el documento'} );

            }            
        }

    }

    const OnHttpUploadSuccess= ( ) => {
       
        setTimeout(() => {
            
            setLoadingFinish( true );

            handleLoadFinish();

        }, 200);
        				
    }

    const handleLoadFinish = () => {

        setTimeout(() => {
            
            Finalizar();

        }, 1000);
    }

    const OnHttpUploadFailure = (errorCode, errorString, sHttpResponse) => {
        console.log(errorCode, errorString, sHttpResponse);

        setLoadingFinish( false );
    }
    
    const loadImagesOrPDFs = () => {

        if( DWObject ){

            setError( '' );

            DWObject.IfShowFileDialog = true;
            DWObject.Addon.PDF.SetResolution( 200 );
            DWObject.Addon.PDF.SetConvertMode( 1 );
            DWObject.LoadImageEx("", 5, () => { setCountPages( DWObject.HowManyImagesInBuffer ); }, ( errorCode, errorString ) => { } );    
        }

    }    
  
    const Anterior = async() => {

        const params = {
            idRecepcion: idRecepcion,
            idEstatus: 2
        }

        setLoadingBack( true );

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200 ){                    
                        
                    setTimeout(() => {
                        
                        setLoadingBack( false );

                        if( response.data.usuarioActualAsignacion ){
                                
                            handleBack();
    
                        }
                        else{

                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  
                            
                            setAjaxFinish( true );
                            
                        }

                    }, 800);                     
                    
                }

            }

        });

    }

    useEffect(() => {
        
        if ( DWObject ) {

            setError( '' );

            const vCount = DWObject.SourceCount;
            let sourceNames = [];
            for (let i = 0; i < vCount; i++){
                sourceNames.push( DWObject.GetSourceNameItems(i) );
            }
            setScanners( sourceNames );
        }

    }, [ DWObject ]);
       
    if( !twainReady ){     
        ReadyTwain();
    }

    return (
        <>
            <Grid item md={6} xs={12} style={{ height: '500px', marginBottom: '3em' }} >

                <div id={ containerId } style={{ Width: '100%' }} >

                </div>
                <br />
                {
                    countPages !== 0
                    &&
                        <Typography fontWeight={'bold'}> Número de hojas: { countPages } </Typography>
                }

            </Grid>

            <Grid item md={6} xs={12}>

                <Grid container spacing={3} >

                    <Grid item md={12} xs={12}>
                    
                        <FormControl fullWidth>
                            <InputLabel id="select-impresoras-label">Impresora(s) disponible(s)</InputLabel>
                    
                            <Select 
                                label="Impresora(s) disponible(s)"
                                labelId="select-impresoras-label"
                                value={ currentScanner } 
                                onChange={ (e) => onSourceChange( e.target.value ) } >
                                
                                <MenuItem value="0" key="0"> Seleccione una impresora </MenuItem>
                                
                                {
                                    scanners.length > 0 
                                    && 
                                    (
                                        scanners.map((_name, _index) =>
                                            <MenuItem value={_name} key={_index}> {_name} </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item md={6} xs={6} textAlign="left"> 
                        <Button 
                            variant="contained" 
                            fullWidth
                            onClick={ acquireImage } 
                            disabled={ currentScanner === "0" || DWObject === null }
                            startIcon={ <ScannerIcon /> }
                        >
                            Escanear documento
                        </Button>
                    </Grid>

                    <Grid item md={6} xs={6} textAlign="right"> 
                        <Button
                            variant="contained" 
                            fullWidth
                            onClick={ loadImagesOrPDFs }
                            startIcon={ <FileUploadIcon /> }
                            color="inherit"
                            disabled={ DWObject === null }
                        >
                            Subir documento                
                        </Button>
                    </Grid>

                    {   
                        error.message && ( 
                            <Grid item md={12} xs={12}>
                                
                                <Alert color='warning' severity={ error.type } variant="filled" > { error.message } </Alert> 

                            </Grid>
                        )
                    }

                </Grid>   

            </Grid>

            <Grid item md={12} xs={12} sx={{ mt: 2 }}>  

                <Grid container >

                    <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  

                        <LoadingButton 
                            variant="contained" 
                            color="inherit"
                            onClick={ Anterior }
                            startIcon={ <ArrowBackIcon /> }
                            loading={ loadingBack }
                        > 
                            Regresar 
                        </LoadingButton>

                    </Grid>

                    <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  

                        <LoadingButton 
                            variant="contained" 
                            onClick={ GuardarDigitalizacion }
                            endIcon={ <CheckCircleIcon /> }
                            loading={ loadingFinish }
                        > 
                            Finalizar 
                        </LoadingButton>

                    </Grid>                                        
                    
                </Grid>

            </Grid>
        </>
    )
}

export default DigitalizarDocumentosScreen;