import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Card, CardContent, Container, Grid, Paper, Snackbar, Step, StepLabel, Stepper, Typography } from '@mui/material';

import AppBarMenu from '../../ui/AppBarMenu';

import { RegistrarPartesScreen } from './RegistrarPartesScreen';
//import { RegistrarPrestacionesScreen } from './RegistrarPrestacionesScreen';
import { RegistrarAnexosScreen } from './RegistrarAnexosScreen';
import { GenerarAcuseScreen } from './GenerarAcuseScreen';
import DigitalizarDocumentosScreen from './DigitalizarDocumentosScreen';

import { ActualizarEstatus, DetalleRecepcion } from '../../../actions/recepcion/recepcionActions';

import { types } from '../../../types/types';
import { InformacionSeguimientoSimple } from '../../ui/InformacionSeguimiento';

export const IrregularScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);

    const [ajaxLoading, setAjaxLoading] = useState(false);
    const [ajaxFinish, setAjaxFinish] = useState(false);    

    const [noSeguimiento, setNoSeguimiento] = useState( '' );

    const [alerta, setAlerta] = useState({ type: 'error', msg: '' });

    const [open, setOpen] = useState( false );

    const { type, msg } = alerta;

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const handleNext = () => {
        setActiveStep( (prevStep) => prevStep + 1);
    }

    const handleBack  = () => {
        setActiveStep( (prevStep) => prevStep - 1);
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);        
    };

    const Finalizar = async () => {

        const params = {
            idRecepcion: Id,
            idEstatus: 47
        }

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200 ){             
                    
                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                    
                    setAjaxFinish( true );

                    dispatch({ type: types.eliminarRecepcion }); 

                }
                else{

                    const { message } = response.data;

                    setAlerta({ type: 'error', msg: message });

                    setOpen( true );

                }

            }

        });           

    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                idRecepcion: Id
            }

            dispatch({ type: types.openLoading });

            await DetalleRecepcion( params ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        if( response.data ){           
                            
                            if( response.data.idEstatus === 50 && response.data.usuarioActualAsignacion){ setActiveStep( 0 ); }
                            //else if( response.data.idEstatus === 51 && response.data.usuarioActualAsignacion){ setActiveStep( 1 ); }
                            else if( response.data.idEstatus === 52 && response.data.usuarioActualAsignacion){ setActiveStep( 1 ); }
                            else if( response.data.idEstatus === 2 && response.data.usuarioActualAsignacion){ setActiveStep( 2 ); }      
                            else if( response.data.idEstatus === 3 && response.data.usuarioActualAsignacion){ setActiveStep( 3 ); }                      
                            else if( response.data.idEstatus === 47 ){  
                                
                                setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                                setAjaxFinish( true );                                 
                            }   
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setAjaxFinish( true ); 
                                }     
                            }  

                            if( response.data.idEstatus !== 47 ){

                                setNoSeguimiento( response.data.id );

                                dispatch({
                                    type: types.actualizarRecepcion,                                
                                    payload: { 
                                        id: response.data.id,
                                        fechaRecepcion: response.data.fechaRecepcion,    
                                        presentante: response.data.presentante,      
                                        acuse: response.data.acuse,                         
                                        acuseFirmado: response.data.acuseFirmado,
                                    }
                                }); 
                                
                                dispatch({
                                    type: types.actualizarIrregular,                              
                                    payload: {
                                        numeroIrregular : response.data.numeroIrregular,
                                        idTipoIrregular : response.data.idTipoIrregular,
                                        ponencia: response.data.ponencia,
                                        referenciaIrregular: response.data.referenciaIrregular,
                                    }
                                }); 
                                
                                dispatch({
                                    type: types.agregarPartes,
                                    payload: response.data.partes,  
                                }); 

                                dispatch({
                                    type: types.agregarPrestaciones,
                                    payload: response.data.prestaciones,  
                                }); 

                                dispatch({
                                    type: types.agregarAnexos,
                                    payload: response.data.anexos,  
                                }); 
                                
                            }

                            setTimeout(() => {
                                
                                setAjaxLoading( true );

                                dispatch({ type: types.closeLoading });

                            }, 1000);

                        }

                    }

                }

            });

        }     
        
        if( Id ){

            Obtener();

        }

    }, [ Id, dispatch ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            <AppBarMenu />    

            <Snackbar open={ open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 3000 } onClose={ handleClose }>
                <Alert onClose={ handleClose } variant="filled" severity={ type }sx={{ width: '100%' }}>
                    { msg }
                </Alert>
            </Snackbar>     

             {
                ajaxFinish
                ?

                    <Box sx={{ marginTop: '10em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        { mensajeFinalizacion }
                                    </Typography>

                                    {
                                        usuarioAsignacion
                                        ?
                                            <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                { usuarioAsignacion }
                                            </Typography>
                                        :
                                        null
                                    }

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>

                :       

                    <Box sx={{ marginTop: '7em', marginBottom: '2em' }} >

                        <Container maxWidth="lg">                    
                            
                            <Stepper activeStep={activeStep} alternativeLabel >                        
                                <Step>
                                    <StepLabel>Registrar parte(s)</StepLabel>
                                </Step>
                                {/* 
                                    <Step>
                                        <StepLabel>Registrar prestacione(s)</StepLabel>
                                    </Step> 
                                */}
                                <Step>
                                    <StepLabel>Registrar anexo(s)</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Generar acuse de recibo</StepLabel>
                                </Step>   
                                <Step>
                                    <StepLabel>Escanear documento(s)</StepLabel>
                                </Step>
                            </Stepper>

                            {
                                activeStep === 0 ? (
                                    <>
                                        <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                            <Grid  item md={12} xs={12}>
                                                <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />
                                            </Grid>

                                            <RegistrarPartesScreen handleNext={ handleNext } idRecepcion={ Id } />

                                        </Grid>
                                    </>
                                ) :

                                /*
                                activeStep === 1 ? (
                                    <>
                                        <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                            <Grid  item md={12} xs={12}>
                                                <Typography variant="h6" sx={{ color: 'gray', mb: 3 }} >
                                                    Número de seguimiento: RT-{noSeguimiento}
                                                </Typography>
                                            </Grid>

                                            <RegistrarPrestacionesScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } />                                      

                                        </Grid>
                                    </>
                                ) :
                                */

                                activeStep === 1 ? (
                                    <>
                                        <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper } > 

                                            <Grid  item md={12} xs={12}>
                                                <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />
                                            </Grid>

                                            <RegistrarAnexosScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } setAjaxFinish={ setAjaxFinish } setUsuarioAsignacion={ setUsuarioAsignacion } />

                                        </Grid>
                                    </> 
                                ) : 

                                activeStep === 2 ? (

                                    <>                                                                   
                                        <Grid container spacing={3} style={{ marginTop: '1em', padding: '2em' }} component={ Paper } > 
                                        
                                            <GenerarAcuseScreen handleNext={ handleNext } handleBack={ handleBack } idRecepcion={ Id } setAjaxFinish={ setAjaxFinish } setUsuarioAsignacion={ setUsuarioAsignacion } />

                                        </Grid>

                                        <Box style={{ height: 35 }}></Box>
                                        
                                    </>                                  
                                                            
                                ) :

                                activeStep === 3 ? (
                                    <>                                
                                        <Grid container spacing={3} style={{ marginTop: '1em', padding: '1em' }} component={ Paper }> 

                                            <Grid  item md={12} xs={12}>
                                                <InformacionSeguimientoSimple noSeguimiento={ 'RT-' + noSeguimiento } />
                                            </Grid>

                                            <DigitalizarDocumentosScreen handleBack={ handleBack } Finalizar={ Finalizar } idRecepcion={ Id } setAjaxFinish={ setAjaxFinish } setUsuarioAsignacion={ setUsuarioAsignacion } />

                                        </Grid>
                                    </> 
                                ) 
                                :
                                null
                            }

                        </Container>

                    </Box>
            }
        </>
    )
}
