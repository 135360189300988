import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, Snackbar, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { types } from '../../../../types/types';

import { GenerarCaratula, GuardarReferenciaIrregular } from '../../../../actions/recepcion/irregularActions';

import { ModalEditarPartes } from '../modalsIrregular/ModalEditarPartes';
import { ModalEditarPrestaciones } from '../modalsIrregular/ModalEditarPrestaciones';

export const DetalleIrregularScreen = ({ edit }) => {

    const dispatch = useDispatch();

    const { tribunal } = useSelector( state => state.auth );
    const { irregular } = useSelector( state => state.acuerdo );

    const [loading, setLoading] = useState( false );
    const [openCaratula, setOpenCaratula] = useState( false );

    const [loadingConfirmar, setLoadingConfirmar] = useState( false );
    const [openConfirmar, setOpenConfirmar] = useState( false );

    const [open, setOpen] = useState( false );
    const [openPrestaciones, setOpenPrestaciones] = useState( false );

    const [referenciaIrregular, setReferenciaIrregular] = useState( '' );
    const [loadingSaveReferencia, setLoadingSaveReferencia] = useState( false );
    const [alert, setAlert] = useState( {} );

    const handleOpenCaratula = async () => {

        setLoading( true );

        await GenerarCaratula( irregular?.idIrregular ?? 0 ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){

                        dispatch({

                            type: types.actualizarIrregularAcuerdo,
                            payload: {                                
                                ...irregular,
                                caratula: response.data.caratula,
                            }
                
                        });
                        
                        setLoading( false );

                        setOpenCaratula( true );                        

                    }

                }

            }

        });    
        
    }

    const eliminarCaratula = () => {

        setLoadingConfirmar( true );

        setTimeout(() => {

            dispatch({

                type: types.actualizarIrregularAcuerdo,
                payload: {                                
                    ...irregular,
                    caratula: '',
                }
    
            });
            
            setLoadingConfirmar( false );

            setOpenConfirmar( false );

        }, 1000);  

    }
    
    const handleActualizarReferencia = async () => {

        setLoadingSaveReferencia( true );

        await GuardarReferenciaIrregular({ idIrregular: irregular.idIrregular, referencia: referenciaIrregular }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                        
                        if( success ){

                            setTimeout(() => {

                                setLoadingSaveReferencia( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                dispatch({
                                    type: types.actualizarIrregularAcuerdo,
                                    payload: {
                                        ...irregular,
                                        referenciaIrregular: referenciaIrregular,
                                    }
                                });

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingSaveReferencia( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingSaveReferencia( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingSaveReferencia( false );
                }

            }

        });     

    }    

    useEffect(() => {
          
        if( irregular?.referenciaIrregular !== '' ){
            setReferenciaIrregular( irregular?.referenciaIrregular ?? '' );
        }

    }, [ irregular ]);  

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ () => setAlert( { open:false }) }>
                        <Alert onClose={ () => setAlert( { open:false }) } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        {
                            irregular?.idTipoIrregular === 1
                            ?
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Número de convenio:
                                </Typography>
                            :
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Número de irregular:
                                </Typography>
                        }
                    </Grid>
                    <Grid item md={10} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                            { irregular?.numeroIrregular }
                        </Typography>
                    </Grid>
                </Grid>      

                {
                    irregular?.idTipoIrregular !== 1
                    &&
                        <Grid container sx={{ mt: 1, mb: 1 }}>
                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Tipo de irregular:
                                </Typography>
                            </Grid>
                            <Grid item md={10} xs={8} >
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                    { irregular?.tipoIrregular }
                                </Typography>
                            </Grid>
                        </Grid>       
                }

                {
                    irregular?.referenciaIrregular
                    ?
                        
                        edit
                        ?

                            <Grid container sx={{ mt: 1, mb: 1 }}>

                                <Grid item md={2} xs={4}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Referencia
                                    </Typography>
                                </Grid>

                                <Grid item xs={10}>      

                                    <Grid container spacing={3}>   

                                        <Grid item xs={10}>

                                            <TextField                              
                                                label="Referencia"
                                                name="referencia"                             
                                                value={ referenciaIrregular }    
                                                multiline
                                                rows={2}
                                                maxRows={3}
                                                onChange={ ( e ) => { setReferenciaIrregular( e.target.value ); } }                                                     
                                                fullWidth           
                                                autoComplete='off'                              
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}              
                                            />

                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton 
                                                onClick={ handleActualizarReferencia }
                                                color='primary'    
                                                disabled={ referenciaIrregular === '' }                                    
                                            >
                                                {
                                                    loadingSaveReferencia
                                                    ?
                                                        <CircularProgress size={20} />
                                                    :
                                                        <SaveIcon />
                                                }
                                            </IconButton>                                        
                                        </Grid>                                            
                                    
                                    </Grid>   

                                </Grid>

                            </Grid>                        
                        :
                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item md={2} xs={4}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Referencia
                                    </Typography>
                                </Grid>
                                <Grid item md={10} xs={8} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { irregular?.referenciaIrregular }
                                    </Typography>
                                </Grid>
                            </Grid>  
                    : null  
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>

                    <Grid item md={2} xs={4}>

                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Actor(es):
                        </Typography>

                    </Grid>

                    <Grid item md={8} xs={6} >
                        <List disablePadding>
                            {
                                irregular?.actor?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                              
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                            { element }
                                        </Typography>                               
                                    </ListItem>
                                ))
                            }                       
                        </List>
                    </Grid>
                    
                    {
                        edit
                        &&                    
                        <Grid item md={2} xs={2} sx={{ textAlign: 'right' }} >

                            <IconButton
                                color='primary'
                                onClick={ () => { setOpen( true ); } }
                            >
                                <EditIcon fontSize='small' />
                            </IconButton>

                        </Grid>                    
                    }

                </Grid>  

                <Grid container sx={{ mt: 1, mb: 1 }}>

                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Demandado(s):
                        </Typography>
                    </Grid>

                    <Grid item md={10} xs={8} >
                        <List disablePadding>
                            {
                                irregular?.demandado?.map( ( element, i  ) => (                                    
                                    <ListItem key={ i } disablePadding>                              
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                            { element }
                                        </Typography>                               
                                    </ListItem>
                                ))
                            }                             
                        </List>
                    </Grid>

                </Grid>    

                {
                    tribunal
                    &&
                        <Grid container sx={{ mt: 3, mb: 1 }}>
                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }} >  

                            {
                                irregular?.caratula === ""
                                ?
                                    <LoadingButton 
                                        variant="contained" 
                                        startIcon={ <FileCopyIcon /> } 
                                        onClick={ handleOpenCaratula } 
                                        loading={ loading }
                                    >
                                        Generar carátula
                                    </LoadingButton>
                                :
                                    <>
                                        <Button 
                                            variant="contained" 
                                            startIcon={ <PrintIcon /> } 
                                            onClick={ () => { setOpenCaratula( true ); } } 
                                        >
                                            Visualizar carátula
                                        </Button>    

                                        {
                                            edit
                                            && 
                                            <IconButton
                                                color='primary'
                                                onClick={ () => { setOpenConfirmar( true ); } }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                            
                                    </>
                            }     

                            </Grid>
                        </Grid>   
                }                                      
                
                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Irregular escaneado:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>

                        {
                            irregular.digitalizacion
                            ?                        
                                <iframe 
                                    title='iframe  Demanda o Escrito Inicial'
                                    style={{ width: '100%', height: '400px' }} 
                                    src={ irregular.digitalizacion }                                
                                />
                            :
                                null
                        }

                    </Grid>
                </Grid>    

            </Box>

            <Dialog open={ openCaratula } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               irregular.caratula
                               ?
                               <iframe title='caratula' src={ irregular.caratula } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró la carátula
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCaratula( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog open={ openConfirmar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar la carátula?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmar( false ); } }> Cancelar </Button>
                    <LoadingButton onClick={ eliminarCaratula } variant='contained' loading={ loadingConfirmar } > Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <ModalEditarPartes
                Id={ irregular?.idIrregular ?? 0 }
                open={ open }
                setOpen={ setOpen }            
            />

            <ModalEditarPrestaciones
                Id={ irregular?.idIrregular ?? 0 }
                open={ openPrestaciones }
                setOpen={ setOpenPrestaciones }            
            />

        </>
    )
}
