import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';

import DateAdapter from '@mui/lab/AdapterMoment';

import moment from 'moment';

import 'moment/locale/es-mx';

import { ConvertToDateTime } from '../../helpers/ConvertToDateTime';

import { types } from '../../types/types';

import '../../css/Calendar.css';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { ObtenerTiposAudiencias } from '../../actions/admin/catalogs/tipoAudienciaActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../actions/admin/users/usuarioAction';

import { AgregarAudiencia, CancelarAudiencia, ModificarAudiencia } from '../../actions/audiencias/agendaActions';

export const ModalAgendarAudiencia = ({ openModal, handleCloseModal, edit, enAudiencia }) => {

    const { juzgado, tribunal } = useSelector(state => state.auth);

    const FechaDefault = new Date();
    const HoraInicioDefault = FechaDefault.getFullYear() + '-' + ( ( '00' +  ( FechaDefault.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + FechaDefault.getDate() ).slice(-2) + ' ' + FechaDefault.getHours() +  ':00';  
    const HoraFinDefault = FechaDefault.getFullYear() + '-' + ( ( '00' +  ( FechaDefault.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + FechaDefault.getDate() ).slice(-2) + ' ' + ( FechaDefault.getHours() + 1) +  ':00';  

    const dispatch = useDispatch();        

    const { Selected, expediente: expedienteAudiencia, audiencia, idAcuerdo } = useSelector( state => state.audiencias );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );

    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const [tipoAudiencia, setTipoAudiencia] = useState( 0 );
    const [tipoAudienciaArray, setTipoAudienciaArray] = useState( [ ] );

    const [valueDate, setValueDate] = useState( new Date( FechaDefault ) );
    const [valueTimeInicio, setValueTimeInicio] = useState( new Date( HoraInicioDefault ) );
    const [valueTimeFin, setValueTimeFin] = useState( new Date( HoraFinDefault ) );  
  
    const [validateActions, setValidateActions] = useState( false );
    const [validateActionsExpedienteAudiencia, setValidateActionsExpedienteAudiencia ] = useState( false );

    const [audienciaCancelada, setAudienciaCancelada] = useState( false );

    const [openMotivo, setOpenMotivo] = useState( false );
    const [motivoCancelacion, setMotivoCancelacion] = useState( '' );

    const [loadingExpediente, setLoadingExpediente] = useState( false );
    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [expediente, setExpediente] = useState( { success: false } );

    const [errors, setErrors] = useState({  });

    const [loading, setLoading] = useState( false );
    const [loadingCancelacion, setLoadingCancelacion] = useState( false );
    
    const handleInputTipoAudiencia = ( e ) => {                 
        setTipoAudiencia( e.target.value ); 
    }       

    const handleInputValueDate = ( value ) => { 
        setValueDate( value ); 
    }

    const handleInputValueTimeInicio = ( value ) => { 
        setValueTimeInicio( value ); 
    }

    const handleInputValueTimeFin = ( value ) => { 
        setValueTimeFin( value ); 
    }

    const handleOpenModalMotivo = () => {
        setOpenMotivo(true);
    }

    const handleCloseModalMotivo = () => {
       
        setOpenMotivo(false);
        setErrors( { } );

    }
    
    const AgregarEvento = async () => {
                      
        const [ Inicio, Fin ] = ConvertToDateTime(valueDate, valueTimeInicio, valueTimeFin);

        const params = {

            idTipoAudiencia: tipoAudiencia,
            idExpediente: expediente.idExpediente,
            fechaInicio: moment( Inicio ).format('YYYY-MM-DDTHH:mm'),
            fechaFin: moment( Fin ).format('YYYY-MM-DDTHH:mm'),
            idAcuerdo: idAcuerdo,
            idAudiencia: audiencia?.idAudiencia ?? 0,
            idJuez: juez,
            idSecretario: secretario
        };

        setLoading( true );

        await AgregarAudiencia( params ).then( response => {
        
            if( response ){

                if( response.status === 200){

                    setTimeout(() => {
                        
                        dispatch( {
                            type: types.audienciasAddNew,
                            payload: { 
                                id: response.data.id,
                                title: response.data.title,
                                idTipoAudiencia: response.data.idTipoAudiencia,
                                start: new Date( response.data.start ), 
                                end: new Date( response.data.end ),         
                                expediente: response.data.expediente,
                                estatus: response.data.estatus,       
                                motivoCancelacion: '',
                                idJuez: juez,
                                idSecretario: secretario
                            } 
                        }); 

                        setLoading( false );
    
                        Cerrar();

                    }, 1000 );                   

                }

            }
        
        }); 
        
    }   

    const ModificarEvento = async () => {

        const [ Inicio, Fin ] = ConvertToDateTime(valueDate, valueTimeInicio, valueTimeFin);

        const params = {
            
            id: Selected.id,
            idTipoAudiencia: tipoAudiencia,
            idExpediente: expediente.idExpediente,
            fechaInicio: moment( Inicio ).format('YYYY-MM-DDTHH:mm'),
            fechaFin: moment( Fin ).format('YYYY-MM-DDTHH:mm'),
            idJuez: juez, 
            idSecretario: secretario
            
        };

        setLoading( true );

        await ModificarAudiencia( params ).then( response => {
        
            if( response ){

                if( response.status === 200){

                    setTimeout(() => {
                        
                        dispatch( {
                            type: types.audienciasUpdated,
                            payload: { 
                                id: response.data.id,
                                audiencia: {
                                    id: response.data.id,
                                    idTipoAudiencia: tipoAudiencia,
                                    title: response.data.title,                    
                                    start: Inicio, 
                                    end: Fin,          
                                    expediente: expediente,       
                                    idJuez: juez,     
                                    idSecretario: secretario,        
                                }
                            } 
                        });
                
                        setLoading( false );
    
                        Cerrar();

                    }, 1000 );                   

                }

            }
        
        }); 
        
    }

    const Cerrar = () => {
        
        setNumeroExpediente( '' );
        setTipoAudiencia( 0 );
        setJuez( 0 );
        setSecretario( 0 );

        handleCloseModal();

        setErrors( { } );

        dispatch( { type: types.audienciaSelectedClear });
    }

    const validateFielsSearch = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;             
            errores.numeroExpediente = 'Debes teclear el número de expediente';
            errores.type = 'warning';

            setLoadingExpediente( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.numeroExpediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( { success: false } );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoadingExpediente( true );

        const valid = validateFielsSearch();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }
                
        if( tribunal ){

            await BuscarExpediente( params ).then( response => {
        
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {

                            setLoadingExpediente( false );

                            if(response.data){      

                                if(response.data.success){
                                    
                                    setExpediente(response.data);                                   
                                    
                                }
                                else{

                                    errorExpediente();

                                }

                            }

                        }, 1500);  

                        setNumeroExpediente('');
                    } 
                    else {

                        setTimeout(() => {

                            setLoadingExpediente( false );
    
                        }, 1500);  

                        setNumeroExpediente('');
                    }
                }
            });

        }
        else if ( juzgado ){

            await BuscarExpediente( params ).then( response => {
    
                setLoadingExpediente( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {

                            setLoadingExpediente( false );

                            if(response.data){
                             
                                if(response.data.success){

                                    setExpediente(response.data); 
                                    
                                    if( response.data.idJuez ){
                                        setJuez( response.data.idJuez ?? 0 );
                                    }
                                    if( response.data.idSecretario ){
                                        setSecretario( response.data.idSecretario ?? 0 );
                                    }
                                }
                                else{

                                    errorExpediente();
                                    
                                }
                            }

                        }, 1500);  

                        setNumeroExpediente('');
                    } 
                    else {

                        setTimeout(() => {

                            setLoadingExpediente( false );
    
                        }, 1500);  

                        setNumeroExpediente('');
                    }
                }
            });

        }
    }    

    const handleGuardarCancelacion = async () => {

        if( motivoCancelacion === '' ){

            setErrors( { } );

            let errores = {};  
                errores.motivoCancelacion = 'Debe escribir el motivo de cancelación';
                errores.type = 'warning';
        
            setErrors( errores );

            return false;
        }

        const params = {
            
            id: Selected.id,
            observaciones: motivoCancelacion

        };

        setLoadingCancelacion( true );

        await CancelarAudiencia( params ).then( response => {
        
            if( response ){

                if( response.status === 200){

                    setTimeout(() => {
                        
                        dispatch( {
                            type: types.audienciasUpdated,
                            payload: { 
                                id: response.data.id,
                                audiencia: {
                                    id: response.data.id,
                                    idTipoAudiencia: response.data.idTipoAudiencia,
                                    title: response.data.title,                    
                                    start: new Date( response.data.start ), 
                                    end: new Date( response.data.end ),  
                                    expediente: response.data.expediente,   
                                    motivoCancelacion: response.data.motivoCancelacion,
                                    estatus: response.data.estatus, 
                                    idJuez: juez,     
                                    idSecretario: secretario,               
                                }
                            } 
                        });
                
                        setLoadingCancelacion( false );
    
                        handleCloseModalMotivo();

                        handleCloseModal();

                    }, 1000 );                   

                }

            }
        
        }); 

    }

    const disableWeekends = (date) => {
        
        return moment( date ).day() === 0 || moment( date ).day() === 6;

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTiposAudiencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoAudienciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {

        if( Selected )
        {      
            setTipoAudiencia( Selected.idTipoAudiencia ?? 0 )    ;
            setJuez( Selected.idJuez ?? 0 );
            setValueDate( Selected.start );
            setValueTimeInicio( Selected.start );
            setValueTimeFin( Selected.end  );
            setExpediente( Selected.expediente ?? {} );     
            setSecretario( Selected.idSecretario ?? 0 );       

            const SelectedStart = new Date(Selected?.start);            
            const FechaSeleted = new Date( SelectedStart.getFullYear() + '-' + ( SelectedStart.getMonth() + 1 ) + '-' + SelectedStart.getDate() );

            const Now = new Date();
            const FechaNow = new Date( Now.getFullYear() + '-' + ( Now.getMonth() + 1 ) + '-' + Now.getDate() );
            
            if( Selected?.estatus === 'Audiencia cancelada' || Selected?.estatus === 'Audiencia celebrada' || Selected?.estatus === 'Audiencia en proceso' ){                
                setValidateActions( false );
                setAudienciaCancelada( true );
            }
            else if( FechaSeleted < FechaNow ){                
                setValidateActions( false );
                setAudienciaCancelada( false );                
            }
            else{
                setValidateActions( true );
                setAudienciaCancelada( false );
            }

            if( expedienteAudiencia && edit ){                    
                if( expedienteAudiencia?.success && Selected.action !== 'Modificar' ){                    
                    setValidateActionsExpedienteAudiencia( true );
                    
                    if( FechaSeleted < FechaNow ){  
                        setExpediente( { success: false } );
                    }
                    else{
                        setExpediente( expedienteAudiencia );
                    }

                }
                else if( expedienteAudiencia?.success && Selected.action === 'Modificar' ){                    
                    
                    if( expedienteAudiencia.idExpediente === Selected.expediente.idExpediente )
                    {
                        setValidateActionsExpedienteAudiencia( true );
                    }
                    else{
                        setValidateActionsExpedienteAudiencia( false );
                    }                    
                }
                else{
                    setValidateActionsExpedienteAudiencia( false );
                }
            }

            if( !edit ){
                setValidateActions( false );            
            }            
        }

    }, [ Selected, expedienteAudiencia, edit ]);

    return (

        <>

            <Dialog open={openModal} maxWidth='md' fullWidth={true}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                
                <DialogTitle>  
                    {      
                        Selected?.estatus === 'Audiencia celebrada' || Selected?.estatus === 'Audiencia en proceso' || Selected?.estatus === 'Audiencia programada'
                        ?
                            Selected?.estatus
                        :
                            Selected?.motivoCancelacion !== '' 
                            ? 
                                'Audiencia cancelada'
                            : 
                                validateActions
                                ?
                                    Selected?.action === 'Modificar' ? 'Modificar audiencia' : 'Agregar audiencia'                        
                                :
                                    null                       
                    }                     
                </DialogTitle>

                <DialogContent id="DialogContentAgendar">
                    
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        
                        <Grid container spacing={3} sx={{ paddingTop: 2 }}>  

                            <Grid item md={12} xs={12} > 
                
                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                    <InputBase 
                                        sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                        placeholder="Buscar por número de expediente"     
                                        name="expediente"
                                        value={ numeroExpediente }                                      
                                        onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                        autoComplete="off"
                                        disabled={ !validateActions || validateActionsExpedienteAudiencia }
                                    /> 

                                    <LoadingButton 
                                        variant='contained' 
                                        sx={{ borderRadius: 10 }} 
                                        loading={ loadingExpediente }
                                        onClick={ handleClicBuscarExpediente }
                                        size='small'
                                        disabled={ !validateActions || validateActionsExpedienteAudiencia }
                                    >
                                        <SearchIcon />
                                    </LoadingButton>   

                                </Paper>
                                {   
                                    errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                                }
                            </Grid>  

                            {
                                expediente.success === true 
                                &&
                                (
                                    <>
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Expediente:
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.numeroExpediente }
                                            </Typography>
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            {
                                                expediente.ponencia
                                                &&                                
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Ponencia:
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            {
                                                expediente.ponencia
                                                &&  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { expediente.ponencia }
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                            {
                                                expediente.actor.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                
                                            </List>                            
                                    
                                        </Grid> 
                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                
                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                  
                                            </List>
                                        </Grid>    

                                    </>
                                )
                            }      

                            <Grid item md={4} xs={12} sx={{ mt: 2 }} >  
                                <DesktopDatePicker
                                    label="Fecha de audiencia"
                                    inputFormat="DD/MM/YYYY"                                
                                    name="valueDate"   
                                    minDate={ validateActions ? moment(FechaDefault) : null }
                                    value={                                         
                                        ( moment( valueDate ).day() !== 0 && moment( valueDate ).day() !== 6 )
                                        ?
                                        moment( valueDate ) 
                                        :
                                        null                                   
                                    }
                                    onChange={ handleInputValueDate }
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={ !validateActions }
                                    shouldDisableDate={ disableWeekends }
                                />
                            </Grid>

                            <Grid item md={4} xs={12} sx={{ mt: 2 }} >  
                                <TimePicker
                                    label="Hora inicio"
                                    inputFormat="HH:mm"     
                                    ampm     
                                    name="valueTimeInicio"                          
                                    value={ moment(valueTimeInicio) }
                                    onChange={ handleInputValueTimeInicio }
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={ !validateActions }
                                />
                            </Grid>

                            <Grid item md={4} xs={12} sx={{ mt: 2 }} >  
                                <TimePicker
                                    label="Hora finalización"
                                    inputFormat="HH:mm"
                                    ampm
                                    name="valueTimeFin"
                                    value={  moment(valueTimeFin) }
                                    onChange={ handleInputValueTimeFin }
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={ !validateActions }
                                />
                            </Grid>                            

                            <Grid item md={12} xs={12} sx={{ mt: 1 }} >  
                                <FormControl fullWidth>
                                    <InputLabel id="select-tipoAudiencia-label">Tipo de Audiencia</InputLabel>
                                
                                    <Select
                                        labelId="select-tipoAudiencia-label"
                                        id="select-tipoAudiencia"                                            
                                        label="Tipo de audiencia"
                                        name="tipoAudiencia"
                                        value={ tipoAudiencia }
                                        onChange={ handleInputTipoAudiencia }
                                        disabled={ !validateActions || !expediente?.success }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>

                                        {
                                            ( 
                                                juzgado && 
                                                ( expediente?.idEtapa === 20 || expediente?.idEtapa === 21 || expediente?.idEtapa === 24 || expediente?.idEtapa === 25 || expediente?.idEtapa === 26 ) && 
                                                ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            && 
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 10; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                           
                                        } 

                                        {
                                            ( 
                                                juzgado && 
                                                expediente?.idEtapa === 24 && 
                                                expediente?.idTipoJuicio === 5 && 
                                                ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            && 
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 19; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                           
                                        } 

                                        {
                                            (
                                                ( juzgado && ( expediente?.idSubEtapa === 1032 || expediente?.idEtapa === 15 || expediente?.idEtapa === 19 ) && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) ) ||
                                                ( juzgado && enAudiencia )                                            
                                            )
                                            &&  
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 11; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                           
                                        } 

                                        {
                                            (
                                                ( juzgado && ( expediente?.idEtapa === 20 || expediente?.idEtapa === 21 ) && ( Selected?.estatus === 'Audiencia programada' ) && tipoAudiencia === 11 && !enAudiencia ) 
                                            )
                                            &&  
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 11; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                           
                                        } 

                                        {
                                            ( juzgado && expediente?.idEtapa === 9 && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            && 
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 12; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                           
                                        } 

                                        {
                                            
                                            ( tribunal && expediente?.idTipoJuicio === 1 && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )                                             
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.tribunal === true && element.idTipoAudiencia !== 8 && element.idTipoAudiencia !== 9; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))                                          
                                        } 

                                        {
                                            ( tribunal && expediente?.idTipoJuicio === 2 && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.tribunal === true && element.idTipoAudiencia === 8; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        } 

                                        {
                                            ( tribunal && expediente?.idTipoJuicio === 3 && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.tribunal === true && element.idTipoAudiencia === 9; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        } 

                                        {
                                            ( Selected?.estatus !== 'Audiencia programada' && Selected?.action !== 'Agregar' )
                                            &&
                                                tipoAudienciaArray                                               
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }           

                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 2 && ( expediente?.idEtapa === 23 || expediente?.idEtapa === 13 )  && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 11; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }         

                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 6 && ( expediente?.idEtapa === 11 || expediente?.idEtapa === 15 || expediente?.idEtapa === 19 || expediente?.idEtapa === 24 )  && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 16; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }             

                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 6 && ( expediente?.idEtapa === 27 || expediente?.idSubEtapa === 1044 )  && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 17; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }        

                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 6 && ( expediente?.idSubEtapa === 1045 ) && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 18; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }              

                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 6 && ( expediente?.idEtapa === 27 && audiencia?.idTipoAudiencia === 17 ) && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 18; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }                  
                                        
                                        {
                                            ( juzgado && expediente?.idTipoJuicio === 5 && ( expediente?.idEtapa === 11 || expediente?.idEtapa === 15)  && ( Selected?.estatus === 'Audiencia programada' || Selected?.action === 'Agregar' ) )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 17; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }    

                                        {
                                            ( juzgado && expediente?.incidente === true && Selected?.action === 'Agregar' )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 5; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }    

                                        {
                                            ( juzgado && Selected?.idTipoAudiencia === 5 && Selected?.estatus === 'Audiencia programada' )
                                            &&
                                                tipoAudienciaArray
                                                .filter( (element ) => { 
                                                    return element.juzgado === true && element.idTipoAudiencia === 5; 
                                                })
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idTipoAudiencia } value={ element.idTipoAudiencia }>{ element.descripcion }</MenuItem>                        
                                                ))
                                        }  
                                    </Select>

                                </FormControl>
                            </Grid>   

                            {
                                juzgado
                                &&
                                <Grid item md={12} xs={12} >

                                    <FormControl fullWidth>
                                        <InputLabel id="select-juez-label">Juez</InputLabel>
                                    
                                        <Select
                                            labelId="select-juez-label"
                                            id="select-juez"                                            
                                            label="Juez"
                                            name="juez"
                                            value={ juez }                                
                                            onChange={ ( e ) => setJuez( e.target.value ) }       
                                            disabled={ !validateActions || !expediente?.success }                                     
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                juezArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                ))
                                            }                                          
                                        </Select>

                                    </FormControl>

                                </Grid>
                            }

                            {
                                juzgado
                                &&
                                <Grid item md={12} xs={12} >

                                    <FormControl fullWidth>
                                        <InputLabel id="select-secretario-label">Secretario</InputLabel>
                                    
                                        <Select
                                            labelId="select-secretario-label"
                                            id="select-secretario"                                            
                                            label="Secretario"
                                            name="secretario"
                                            value={ secretario }                                
                                            onChange={ ( e ) => setSecretario( e.target.value ) }       
                                            disabled={ !validateActions || !expediente?.success }                                     
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                secretarioArray.map( ( elem ) => (
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                ))
                                            }                                          
                                        </Select>

                                    </FormControl>

                                </Grid>
                            }

                            {                 
                                Selected?.motivoCancelacion 
                                ?                                     
                                <Grid item md={12} xs={12} sx={{ mt: 3, textAlign: 'center' }}>  
                                    <Typography sx={{ fontSize: 20 }}>
                                        Motivo de cancelación: { Selected?.motivoCancelacion } 
                                    </Typography>                                        
                                </Grid>  
                                : 
                                null                     
                            }                              

                        </Grid>
                            
                    </LocalizationProvider>

                </DialogContent>

                <DialogActions>

                    <Button 
                        onClick={ Cerrar } 
                        color="primary" 
                        variant="text"
                    >
                        Cerrar
                    </Button>                     

                    {
                        Selected?.action === 'Agregar' && validateActions
                        &&
                        ( 
                            <LoadingButton 
                                onClick={ AgregarEvento } 
                                color="primary" 
                                variant="contained" 
                                startIcon={ <AddCircleOutlineRoundedIcon /> } 
                                loading={ loading }
                                disabled={ 
                                    juzgado
                                    ?
                                        !expediente.success || tipoAudiencia === 0 || juez === 0 || secretario === 0 || ( ( moment( valueDate ).day() === 0 || moment( valueDate ).day() === 6 ) ) 
                                    :                                    
                                        !expediente.success || tipoAudiencia === 0 || ( ( moment( valueDate ).day() === 0 || moment( valueDate ).day() === 6 ) ) 
                                }
                            >
                                Agregar audiencia
                            </LoadingButton>
                        )
                    }

                    {
                        Selected?.action === 'Modificar' && validateActions && !audienciaCancelada
                        &&
                        (                    
                            <>
                                <LoadingButton 
                                    onClick={ ModificarEvento } 
                                    color="primary" 
                                    variant="contained" 
                                    startIcon={ <AddCircleOutlineRoundedIcon /> } 
                                    loading={ loading }
                                    disabled={ 
                                        juzgado
                                        ?
                                            !expediente.success || tipoAudiencia === 0 || juez === 0 || secretario === 0
                                        :
                                            !expediente.success || tipoAudiencia === 0 
                                    }                                    
                                >
                                    Modificar audiencia 
                                </LoadingButton>
                                    
                                <Button 
                                    onClick={ handleOpenModalMotivo } 
                                    color="error" 
                                    variant="contained" 
                                    startIcon={ <ClearIcon /> }
                                    disabled={ 
                                        juzgado
                                        ?
                                            !expediente.success || tipoAudiencia === 0 || juez === 0 || secretario === 0
                                        :
                                            !expediente.success || tipoAudiencia === 0 
                                    }      
                                >
                                    Cancelar Audiencia
                                </Button> 
                            </>
                        )
                    }

                </DialogActions>

            </Dialog>

            <Dialog open={ openMotivo } maxWidth='sm' fullWidth={true}>

                <DialogContent>

                    <TextField
                        sx={{ mt: 1 }}
                        label="Motivo de cancelación"
                        variant="outlined"   
                        autoFocus     
                        multiline
                        rows={4}                                        
                        fullWidth
                        value={ motivoCancelacion }
                        onChange={ ( e ) => { setMotivoCancelacion( e.target.value ) } }
                    />

                    {   
                        errors.motivoCancelacion && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.motivoCancelacion  } </Alert> )
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseModalMotivo }>Cancelar</Button>
                    
                    <LoadingButton 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ handleGuardarCancelacion} 
                        loading={ loadingCancelacion }
                    >
                        Guardar                        
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
